var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 24"
    }
  }, [_c('path', {
    attrs: {
      "d": "M0 6a6 6 0 0 1 6-6h18a6 6 0 0 1 6 6v12a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6Z"
    }
  }), _vm._v(" "), _c('path', {
    attrs: {
      "fill": "#fff",
      "d": "M5.743 18h2.552v-5.455c.147-.34.344-.604.59-.796.246-.191.539-.287.878-.287.394 0 .692.112.894.336.202.225.303.586.303 1.083V18h2.56v-5.455c.148-.334.347-.596.599-.788.251-.197.547-.295.886-.295.388.006.68.12.877.345.203.218.304.571.304 1.058V18h2.56v-5.193c0-1.175-.255-2.015-.764-2.518-.503-.509-1.17-.763-2.001-.763-.574 0-1.113.126-1.616.378a2.838 2.838 0 0 0-1.181 1.074h-.025a2.077 2.077 0 0 0-.927-1.083c-.432-.246-.94-.369-1.526-.369a3.11 3.11 0 0 0-1.41.32 2.562 2.562 0 0 0-1.026.886h-.041l-.033-1.025H5.743V18ZM20.739 16.794c0 .378.128.703.385.976.257.268.624.402 1.1.402.48 0 .847-.134 1.099-.402.257-.268.385-.593.385-.976s-.128-.708-.385-.976-.624-.402-1.1-.402c-.48 0-.85.134-1.107.402a1.374 1.374 0 0 0-.377.976Zm0-6.111c0 .383.125.708.377.976.257.268.626.402 1.107.402.476 0 .843-.134 1.1-.402.257-.268.385-.593.385-.976 0-.377-.128-.7-.385-.968-.252-.268-.618-.402-1.1-.402-.475 0-.842.134-1.099.402s-.385.59-.385.968Z"
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }